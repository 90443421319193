import { feeTypeMap } from '@/shared/constants';
import {
  type SwapRequestFragment,
  type SwapFeeType,
  type ChainflipAsset,
  type SwapRequestOverviewFragment,
  type DepositFailedReason,
} from '@/shared/graphql/generated/graphql';
import { TokenAmount, chainflipAssetMap } from '@/shared/utils';

export const getEgressStatus = (
  egress: NonNullable<SwapRequestFragment['egress']>,
  isRefund?: boolean,
) => {
  const prefix = isRefund ? 'REFUND_' : '';
  if (egress.broadcast) {
    if (egress.broadcast.broadcastSuccessEventId) return `${prefix}BROADCAST_SUCCEEDED` as const;
    if (egress.broadcast.broadcastAbortedEventId) return `${prefix}BROADCAST_ABORTED` as const;
    return `${prefix}BROADCAST_REQUESTED` as const;
  }
  return `${prefix}EGRESS_SCHEDULED` as const;
};

export const getPartialRefundStatus = (
  swapEgress: NonNullable<SwapRequestFragment['egress']>,
  refundEgress: NonNullable<SwapRequestFragment['refundEgress']>,
) => {
  if (
    swapEgress.broadcast?.broadcastSuccessEventId &&
    refundEgress.broadcast?.broadcastSuccessEventId
  )
    return 'PARTIAL_REFUND_BROADCAST_SUCCEEDED';
  if (swapEgress.broadcast?.broadcastAbortedEventId) return getEgressStatus(swapEgress);
  if (refundEgress.broadcast?.broadcastAbortedEventId) return getEgressStatus(refundEgress, true);
  return 'PARTIAL_REFUND_REQUESTED';
};

const isInternalSwap = (req: SwapRequestFragment | SwapRequestOverviewFragment) =>
  req.type === 'NETWORK_FEE' || req.type === 'INGRESS_EGRESS_FEE';

export const getSwapStatus = (swapRequest: SwapRequestFragment | SwapRequestOverviewFragment) => {
  if (swapRequest.ignoredEgressId) return 'EGRESS_IGNORED';
  if (swapRequest.ignoredRefundEgressId) return 'REFUND_EGRESS_IGNORED';
  if (swapRequest.egress && swapRequest.refundEgress)
    return getPartialRefundStatus(swapRequest.egress, swapRequest.refundEgress);
  if (swapRequest.egress) return getEgressStatus(swapRequest.egress);
  if (swapRequest.refundEgress) return getEgressStatus(swapRequest.refundEgress, true);
  if (isInternalSwap(swapRequest) && swapRequest.executedSwaps.totalCount === 1) return 'COMPLETE';
  if (swapRequest.completedEventId && swapRequest.executedSwaps.totalCount === 0)
    return 'CCM_FAILED'; // CcmFailed event will result in an empty SwapRequest for version <1.7

  return 'SWAP_SCHEDULED';
};

export const getDcaParams = (swapRequest: SwapRequestFragment | SwapRequestOverviewFragment) => ({
  numberOfChunks: swapRequest.numberOfChunks ?? swapRequest.channel?.numberOfChunks ?? 1,
  chunkIntervalBlocks: swapRequest.chunkIntervalBlocks ?? swapRequest.channel?.chunkIntervalBlocks,
});

const createGuard =
  <T extends SwapStatus>(...guards: T[]) =>
  (status: SwapStatus): status is T =>
    guards.includes(status as T);

export const isSuccessStatus = createGuard('BROADCAST_SUCCEEDED', 'COMPLETE');

export const isFullyRefundedStatus = createGuard('REFUND_BROADCAST_SUCCEEDED');

export const isPartiallyRefundingStatus = createGuard('PARTIAL_REFUND_REQUESTED');

export const isPartiallyRefundedStatus = createGuard('PARTIAL_REFUND_BROADCAST_SUCCEEDED');

export const isFailedStatus = createGuard(
  'BROADCAST_ABORTED',
  'EGRESS_IGNORED',
  'REFUND_BROADCAST_ABORTED',
  'REFUND_EGRESS_IGNORED',
  'CCM_FAILED',
);

export const isInProgressStatus = (status: SwapStatus) =>
  !isSuccessStatus(status) &&
  !isFailedStatus(status) &&
  !isFullyRefundedStatus(status) &&
  !isPartiallyRefundedStatus(status);

export type SwapStatus = ReturnType<typeof getSwapStatus>;

type Fee = SwapRequestFragment['fees']['nodes'][number];

export const getFee = (fees: Fee[], feeType: SwapFeeType, asset?: ChainflipAsset) => {
  const fee = fees.find(({ type, asset: feeAsset }) => {
    if (type !== feeType) return false;
    if (asset && asset !== feeAsset) return false;
    return true;
  });

  return (
    fee && {
      asset: fee.asset,
      amount: new TokenAmount(fee.amount, chainflipAssetMap[fee.asset].decimals),
      valueUsd: Number(fee.valueUsd ?? 0),
      label: feeTypeMap[feeType],
    }
  );
};

export const depositFailedMessages: Record<DepositFailedReason, string> = {
  BELOW_MINIMUM_DEPOSIT: 'The deposited amount was below the minimum required',
  NOT_ENOUGH_TO_PAY_FEES: 'The deposited amount was not enough to pay the fees',
  CCM_UNSUPPORTED_FOR_TARGET_CHAIN: 'The destination chain does not support CCM',
  TRANSACTION_REJECTED_BY_BROKER: 'The deposit was rejected by the broker',
  CCM_INVALID_METADATA: 'The provided metadata could not be decoded',
  INVALID_DESTINATION_ADDRESS: 'The provided destination address could not be decoded',
  DEPOSIT_WITNESS_REJECTED: 'An error occurred while witnessing the deposit',
  INVALID_BROKER_FEES: 'The broker fees were improperly formatted',
  INVALID_DCA_PARAMETERS: 'The DCA parameters were improperly formatted',
  INVALID_REFUND_PARAMETERS: 'The refund parameters were improperly formatted',
};
