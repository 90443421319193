import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { assetConstants } from '@chainflip/utils/chainflip';
import { isInteger } from '@chainflip/utils/string';
import classNames from 'classnames';
import { AgeWithTooltip, Button, CompactSwapRoute, Link, SearchInput } from '@/shared/components';
import { Addresses } from '@/shared/components/Addresses';
import Pill from '@/shared/components/flip-ui-kit/Pill';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import TableRow from '@/shared/components/TableRow';
import { type SwapRequestOverviewFragment } from '@/shared/graphql/generated/graphql';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import useRemotePagination from '@/shared/hooks/useRemotePagination';
import { ChunksIcon, ClockIcon, DocIcon, TimeBoost } from '@/shared/icons/small';
import { chainflipAssetMap, getSwapCompletionTime } from '@/shared/utils';
import { getDcaParams, getSwapStatus, isSuccessStatus } from '../../helpers/swap';
import { getAllSwapsQuery, getSwapByNativeIdQuery } from '../../queries/swaps';
import { SwapStatusPill } from '../SwapStatusPill';

export const SwapRow = ({
  swapRequest,
  highlightAddress,
  setHighlightAddress,
}: {
  swapRequest: NonNullable<SwapRequestOverviewFragment>;
  highlightAddress?: string;
  setHighlightAddress?: (address: string) => void;
}) => {
  const sourceChain = assetConstants[swapRequest.sourceAsset].chain;
  const destinationChain = assetConstants[swapRequest.destinationAsset].chain;
  const status = getSwapStatus(swapRequest);
  const swapPath = `/swaps/${swapRequest.nativeId}`;
  const depositChannelCreationTimestamp = swapRequest.channel?.issuedBlock.timestamp;
  const egressTimestamp = swapRequest.egress?.event?.block.timestamp;
  const depositTimestamp = swapRequest.depositBlock?.stateChainTimestamp;
  const preDepositBlockTimestamp = swapRequest.preDepositBlock?.stateChainTimestamp;
  const destinationAddress = swapRequest.destinationAddress ?? '';
  const refundAddress = swapRequest.channel?.fokRefundAddress;
  const numberOfExecutedChunks = swapRequest.executedSwaps.totalCount;
  const { numberOfChunks, chunkIntervalBlocks } = getDcaParams(swapRequest);

  return (
    <TableRow
      href={swapPath}
      className="transition:ease-in h-[91px] text-xs duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
    >
      <td className="whitespace-nowrap font-aeonikMono">
        <div className="flex flex-col gap-y-3 lg:flex-row">
          <div className="flex flex-col justify-center gap-y-1 pr-1 lg:w-2/3">
            <Link href={swapPath} underline noPropagate>
              #{swapRequest.nativeId}
            </Link>
            <span className="flex items-center gap-x-1 font-aeonikRegular">
              Swap {chainflipAssetMap[swapRequest.sourceAsset].symbol} for{' '}
              {chainflipAssetMap[swapRequest.destinationAsset].symbol}
              {swapRequest.type === 'CCM' && <Pill text="CCM" color="neutral" Icon={DocIcon} />}
            </span>
          </div>
          <Addresses
            destinationAddress={destinationAddress}
            destinationChain={destinationChain}
            setHighlightAddress={setHighlightAddress}
            highlightAddress={highlightAddress}
            refundAddress={refundAddress}
            sourceChain={sourceChain}
          />
        </div>
      </td>

      <td>
        <div className="inline-flex">
          <CompactSwapRoute
            routeInfo={{
              destinationAsset: swapRequest.destinationAsset,
              sourceAsset: swapRequest.sourceAsset,
            }}
            swapRequest={swapRequest}
            withTooltip
          />
        </div>
      </td>

      <td className="min-w-[150px]">
        <div className="flex gap-x-1">
          <SwapStatusPill status={status} />
          {chunkIntervalBlocks && (
            <Pill
              color="neutral"
              text={`${numberOfExecutedChunks}/${numberOfChunks}`}
              Icon={ChunksIcon}
              iconColor="text-cf-light-3"
              maxWidth={false}
            />
          )}
        </div>
      </td>

      <td className="min-w-[150px]">
        {isSuccessStatus(status) && egressTimestamp && depositTimestamp && (
          <div className="flex items-center gap-x-1 font-aeonikMono text-12">
            {swapRequest.effectiveBoostFeeBps != null && swapRequest.effectiveBoostFeeBps > 0 ? (
              <TimeBoost className="text-cf-pink-1" />
            ) : (
              <ClockIcon />
            )}
            {getSwapCompletionTime({
              sourceChain,
              depositChannelCreationTimestamp: depositChannelCreationTimestamp
                ? new Date(depositChannelCreationTimestamp)
                : undefined,
              depositTimestamp: new Date(depositTimestamp),
              preDepositBlockTimestamp: preDepositBlockTimestamp
                ? new Date(preDepositBlockTimestamp)
                : undefined,
              egressTimestamp: new Date(egressTimestamp),
              exact: false,
            })}
          </div>
        )}
      </td>

      <td
        className="whitespace-nowrap text-right font-aeonikMono text-cf-light-2"
        title={new Date(swapRequest.requestedEvent.block.timestamp).toLocaleString()}
      >
        <AgeWithTooltip timestamp={swapRequest.requestedEvent.block.timestamp} />
      </td>
    </TableRow>
  );
};

export default function LatestSwapsTable({ preview = false }: { preview?: boolean }) {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const [highlightAddress, setHighlightAddress] = useState('');

  const {
    data: swapsByDestinationAddress,
    isLoading: swapsByDestinationAddressLoading,
    paginationControls: allSwapsPaginationControls,
  } = useRemotePagination(getAllSwapsQuery, {
    useQueryParam: !preview,
    variables: {
      destinationAddress: query.toLowerCase(),
    },
    enabled: !isInteger(query),
    refetchInterval: 30_000,
  });

  const { data: swapsByNativeId, isLoading: swapsByNativeIdLoading } = useGqlQuery(
    getSwapByNativeIdQuery,
    {
      variables: {
        nativeId: query,
      },
      enabled: isInteger(query),
      refetchInterval: 30_000,
    },
  );

  const swaps = useMemo(
    () =>
      swapsByDestinationAddress?.allSwapRequests?.edges ||
      (swapsByNativeId?.swapRequest && [{ node: swapsByNativeId.swapRequest }]) ||
      [],
    [swapsByNativeId, swapsByDestinationAddress],
  );

  const swapTableRows = useMemo(
    () =>
      swaps
        .slice(0, preview ? 10 : swaps.length)
        .map(({ node }) => (
          <SwapRow
            key={node.id}
            swapRequest={node}
            highlightAddress={highlightAddress}
            setHighlightAddress={setHighlightAddress}
          />
        )),
    [swaps, highlightAddress],
  );

  const paginationControls = preview ? undefined : allSwapsPaginationControls;

  return (
    <div className="flex w-full flex-col space-y-8">
      <div
        className={classNames(
          `flex flex-col gap-y-4 lg:flex-row lg:items-center`,
          preview ? 'gap-x-4' : 'justify-between',
        )}
      >
        <div className={classNames(`text-left text-cf-white`, preview ? 'text-20' : 'text-[32px]')}>
          Latest swaps
        </div>
        {preview ? (
          <Button
            type="secondary-standard"
            size="small"
            iconPos="right"
            onClick={() => router.push('/swaps')}
          >
            View All
          </Button>
        ) : (
          <div className="order-1 ml-auto w-full truncate lg:order-none lg:w-[32rem]">
            <SearchInput
              placeholder="Search by swap ID or destination address"
              value={query}
              onChange={setQuery}
            />
          </div>
        )}
      </div>
      <TableV2
        columns={[
          'Swap Details',
          'Route',
          'Status',
          'Completed in',
          { name: 'Age', alignment: 'right' },
        ]}
        isLoading={swapsByDestinationAddressLoading || swapsByNativeIdLoading}
        rows={swapTableRows}
        paginationControls={paginationControls}
        thClassName="py-4 px-4 text-sm text-cf-light-2"
        emptyRowsContent="No swaps here yet"
      />
    </div>
  );
}
