import { unreachable } from '@chainflip/utils/assertion';
import { type ChainflipAsset, type ChainflipChain } from '@chainflip/utils/chainflip';
import {
  ArbitrumLogo,
  BitcoinLogo,
  EthereumLogo,
  PolkadotLogo,
  SolanaLogo,
} from '@/shared/assets/chain-logos';
import {
  ArbitrumTransparentLogo,
  BitcoinTransparentLogo,
  EthereumTransparentLogo,
  PolkadotTransparentLogo,
  SolanaTransparentLogo,
} from '@/shared/assets/chain-transparent-logos';
import {
  BtcLogo,
  DotLogo,
  EthLogo,
  FlipLogo,
  SolLogo,
  UsdcLogo,
  UsdtLogo,
} from '@/shared/assets/token-logos';
import { isTestnet } from '@/shared/featureFlags';
import { arbiscanUrl, etherscanUrl, solscanUrl } from './helpers';

export const assetStyles = {
  Eth: {
    color: '#268AFF',
    logo: EthLogo,
  },
  Flip: {
    color: '#FFFFFF',
    logo: FlipLogo,
  },
  Usdc: {
    color: '#70A4EC',
    logo: UsdcLogo,
  },
  Usdt: {
    color: '#479079',
    logo: UsdtLogo,
  },
  Dot: {
    color: '#FF33AF',
    logo: DotLogo,
  },
  Btc: {
    color: '#FBCB9D',
    logo: BtcLogo,
  },
  ArbUsdc: {
    color: '#457DA7',
    logo: UsdcLogo,
  },
  ArbEth: {
    color: '#9CD2E6',
    logo: EthLogo,
  },
  Sol: {
    color: '#B695EF',
    logo: SolLogo,
  },
  SolUsdc: {
    color: '#9DCCED',
    logo: UsdcLogo,
  },
} as const satisfies Record<
  ChainflipAsset,
  {
    color: string;
    logo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  }
>;

export const chainStyles = {
  Bitcoin: {
    logo: BitcoinLogo,
    transparentLogo: BitcoinTransparentLogo,
  },
  Ethereum: {
    logo: EthereumLogo,
    transparentLogo: EthereumTransparentLogo,
  },
  Solana: {
    logo: SolanaLogo,
    transparentLogo: SolanaTransparentLogo,
  },
  Arbitrum: {
    logo: ArbitrumLogo,
    transparentLogo: ArbitrumTransparentLogo,
  },
  Polkadot: {
    logo: PolkadotLogo,
    transparentLogo: PolkadotTransparentLogo,
  },
} as const satisfies Record<
  ChainflipChain,
  {
    logo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
    transparentLogo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  }
>;

export const boostFeeTiers = [5, 10, 30];
export type BoostFeeTier = (typeof boostFeeTiers)[number];

export const buildChainflipExplorerLink = (
  blockIndex: string | undefined,
  path: 'events' | 'swaps' | 'channels' = 'events',
) => {
  if (!blockIndex) return undefined;

  return new URL(`/${path}/${blockIndex}`, process.env.NEXT_PUBLIC_EXPLORER_URL).toString();
};

export const buildExplorerLink = (
  chain: ChainflipChain,
  type: 'address' | 'block' | 'tx',
  value: string | number,
): string => {
  const blockstreamUrl = `https://blockstream.info${isTestnet ? '/testnet' : ''}`;

  switch (chain) {
    case 'Bitcoin':
      if (type === 'block') {
        return `${blockstreamUrl}/block-height/${value}?q=${value}`;
      }
      if (type === 'address') {
        return `${blockstreamUrl}/address/${value}`;
      }
      if (type === 'tx') {
        return `${blockstreamUrl}/tx/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Polkadot':
      if (type === 'block') return `https://polkadot.subscan.io/block/${value}`;
      if (type === 'address') {
        return `https://polkadot.subscan.io/account/${value}`;
      }
      if (type === 'tx') {
        return `https://polkadot.subscan.io/extrinsic/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Ethereum':
      if (type === 'block') return `${etherscanUrl()}/block/${value}`;
      if (type === 'address') return `${etherscanUrl()}/address/${value}`;
      if (type === 'tx') return `${etherscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Arbitrum':
      if (type === 'block') return `${arbiscanUrl()}/block/${value}`;
      if (type === 'address') return `${arbiscanUrl()}/address/${value}`;
      if (type === 'tx') return `${arbiscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Solana':
      if (type === 'block') return `${solscanUrl(`/block/${value}`)}`;
      if (type === 'address') return `${solscanUrl(`/account/${value}`)}`;
      if (type === 'tx') return `${solscanUrl(`/tx/${value}`)}`;
      return unreachable(type, 'unhandled link type');
    default:
      return unreachable(chain, 'unhandled chain');
  }
};
